import { currencyDetailsConstant, statusResponse } from '../_constants';

let initialState = {
    currencyDetails: []
};

export function currencyDetails(state = initialState, action) {
    switch (action.type) {
        case currencyDetailsConstant.GET_CURRENCY_DETAILS_REQUEST:
            return {
                ...state,
                currencyDetailsList: action.data,
                get_currency_details_status: statusResponse.IN_PROGRESS
            };
        case currencyDetailsConstant.GET_CURRENCY_DETAILS_SUCCESS:
            return {
                ...state,
                currencyDetailsList: action.data,
                get_currency_details_status: statusResponse.SUCCESS
            };
        case currencyDetailsConstant.GET_CURRENCY_DETAILS_FAILURE:
            return {
                ...state,
                currencyDetailsList: action.data,
                get_currency_details_status: statusResponse.FAILURE
            };
        case currencyDetailsConstant.DELETE_CURRENCY_DETAILS_REQUEST:
            return {
                ...state,
                delete_currency_details_status: statusResponse.IN_PROGRESS
            };
        case currencyDetailsConstant.DELETE_CURRENCY_DETAILS_SUCCESS:
            return {
                ...state,
                delete_currency_details_status: statusResponse.SUCCESS
            };
        case currencyDetailsConstant.DELETE_CURRENCY_DETAILS_FAILURE:
            return {
                ...state,
                delete_currency_details_status: statusResponse.FAILURE
            };
        case currencyDetailsConstant.EDIT_CURRENCY_DETAILS_REQUEST:
            return {
                ...state,
                editedCurrencyDetail: action.data,
                edit_currency_details_status: statusResponse.IN_PROGRESS
            };
        case currencyDetailsConstant.EDIT_CURRENCY_DETAILS_SUCCESS:
            return {
                ...state,
                editedCurrencyDetail: action.data,
                edit_currency_details_status: statusResponse.SUCCESS
            };
        case currencyDetailsConstant.EDIT_CURRENCY_DETAILS_FAILURE:
            return {
                ...state,
                editedCurrencyDetail: action.data,
                edit_currency_details_status: statusResponse.FAILURE
            };
        case currencyDetailsConstant.ADD_CURRENCY_DETAILS_REQUEST:
            return {
                ...state,
                addedCurrencyDetail: action.data,
                add_currency_details_status: statusResponse.IN_PROGRESS
            };
        case currencyDetailsConstant.ADD_CURRENCY_DETAILS_SUCCESS:
            return {
                ...state,
                addedCurrencyDetail: action.data,
                add_currency_details_status: statusResponse.SUCCESS
            };
        case currencyDetailsConstant.ADD_CURRENCY_DETAILS_FAILURE:
            return {
                ...state,
                addedCurrencyDetail: action.data,
                add_currency_details_status: statusResponse.FAILURE
            };
        default:
            return state;
    }
}