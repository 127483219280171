import { languageConstants } from '../_constants';

let initialState = {
    language: []
};

export function language(state = initialState, action){
    console.log(action, "saran")
    switch(action.type){
        case languageConstants.SET_LANGUAGE:
            return {
                ...state,
                language: action.data
            };
        default:
            return state;
    }
}