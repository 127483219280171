import { locationConstants, statusResponse } from '../_constants';

export function location(state = {}, action) {
    switch (action.type) {
        case locationConstants.GET_LOCATION_REQUEST:
            return {
                ...state,
                get_location_status: statusResponse.IN_PROGRESS,
                locationdata: action.data
            };
        case locationConstants.GET_LOCATION_SUCCESS:
            return {
                ...state,
                get_location_status: statusResponse.SUCCESS,
                locationdata: action.data
            };
        case locationConstants.GET_LOCATION_FAILURE:
            return {
                ...state,
                get_location_status: statusResponse.FAILURE,
                locationdata: null
            };
        case locationConstants.GET_LANGUAGE_REQUEST:
            return {
                ...state,
                get_language_status: statusResponse.IN_PROGRESS,
                language: action.data
            };
        case locationConstants.GET_LANGUAGE_SUCCESS:
            return {
                ...state,
                get_language_status: statusResponse.SUCCESS,
                language: action.data
            };
        case locationConstants.GET_LANGUAGE_FAILURE:
            return {
                ...state,
                get_language_status: statusResponse.FAILURE,
                language: null
            };
        case locationConstants.DELETE_LOCATION_REQUEST:
            return {
                ...state,
                delete_location_status: statusResponse.IN_PROGRESS,
                deletelocation: action.data
            };
        case locationConstants.DELETE_LOCATION_SUCCESS:
            return {
                ...state,
                delete_location_status: statusResponse.SUCCESS,
                deletelocation: action.data
            };
        case locationConstants.DELETE_LOCATION_FAILURE:
            return {
                ...state,
                delete_location_status: statusResponse.FAILURE,
                deletelocation: null
            };
        case locationConstants.ADD_LOCATION_REQUEST:
            return {
                ...state,
                add_location_status: statusResponse.IN_PROGRESS,
                add_location_data: action.data
            };
        case locationConstants.ADD_LOCATION_SUCCESS:
            return {
                ...state,
                add_location_status: statusResponse.SUCCESS,
                add_location_data: action.data
            };
        case locationConstants.ADD_LOCATION_FAILURE:
            return {
                ...state,
                add_location_status: statusResponse.FAILURE,
                add_location_data: null
            };
        case locationConstants.GET_EDIT_LOCATION_REQUEST:
            return {
                ...state,
                get_edit_location_status: statusResponse.IN_PROGRESS,
                get_edit_location_data: action.data
            };
        case locationConstants.GET_EDIT_LOCATION_SUCCESS:
            return {
                ...state,
                get_edit_location_status: statusResponse.SUCCESS,
                get_edit_location_data: action.data
            };
        case locationConstants.GET_EDIT_LOCATION_FAILURE:
            return {
                ...state,
                get_edit_location_status: statusResponse.FAILURE,
                get_edit_location_data: null
            };
        case locationConstants.EDIT_LOCATION_REQUEST:
            return {
                ...state,
                edit_location_status: statusResponse.IN_PROGRESS,
                edit_location_data: action.data
            };
        case locationConstants.EDIT_LOCATION_SUCCESS:
            return {
                ...state,
                edit_location_status: statusResponse.SUCCESS,
                edit_location_data: action.data
            };
        case locationConstants.EDIT_LOCATION_FAILURE:
            return {
                ...state,
                edit_location_status: statusResponse.FAILURE,
                edit_location_data: null
            };
        case locationConstants.GET_COUNTRY_LIST_REQUEST:
            return {
                ...state,
                get_country_list_status: statusResponse.IN_PROGRESS,
                countryListData: action.data
            };
        case locationConstants.GET_COUNTRY_LIST_SUCCESS:
            return {
                ...state,
                get_country_list_status: statusResponse.SUCCESS,
                countryListData: action.data
            };
        case locationConstants.GET_COUNTRY_LIST_FAILURE:
            return {
                ...state,
                get_country_list_status: statusResponse.FAILURE,
                countryListData: null
            };
        case locationConstants.UPLOAD_COUNTRY_LOCATION_REQUEST:
            return {
                ...state,
                upload_country_location_status: statusResponse.IN_PROGRESS,
                upload_res: action.data
            };
        case locationConstants.UPLOAD_COUNTRY_LOCATION_SUCCESS:
            return {
                ...state,
                upload_country_location_status: statusResponse.SUCCESS,
                upload_res: action.data
            };
        case locationConstants.UPLOAD_COUNTRY_LOCATION_FAILURE:
            return {
                ...state,
                upload_country_location_status: statusResponse.FAILURE,
                upload_res: null
            };
        case locationConstants.UPLOAD_CITY_LOCATION_REQUEST:
            return {
                ...state,
                upload_city_location_status: statusResponse.IN_PROGRESS,
                upload_city_res: action.data
            };
        case locationConstants.UPLOAD_CITY_LOCATION_SUCCESS:
            return {
                ...state,
                upload_city_location_status: statusResponse.SUCCESS,
                upload_city_res: action.data
            };
        case locationConstants.UPLOAD_CITY_LOCATION_FAILURE:
            return {
                ...state,
                upload_city_location_status: statusResponse.FAILURE,
                upload_city_res: null
            };
        default:
            return state;
    }
}