import { notificationsConstants, statusResponse } from '../_constants';

let initialState = {
    notificationTemplateList: []
};

export function notifications(state = initialState, action) {
    switch (action.type) {
        case notificationsConstants.GET_NOTIFICATION_TEMPLATES_REQUEST:
            return {
                ...state,
                notificationTemplateList: action.data,
                get_notification_template_status: statusResponse.IN_PROGRESS
            };
        case notificationsConstants.GET_NOTIFICATION_TEMPLATES_SUCCESS:
            return {
                ...state,
                notificationTemplateList: action.data,
                get_notification_template_status: statusResponse.SUCCESS
            };
        case notificationsConstants.GET_NOTIFICATION_TEMPLATES_FAILURE:
            return {
                ...state,
                notificationTemplateList: action.data,
                get_notification_template_status: statusResponse.FAILURE
            };
        case notificationsConstants.ADD_NOTIFICATION_TEMPLATES_REQUEST:
            return {
                ...state,
                add_notification_template_status: statusResponse.IN_PROGRESS
            };
        case notificationsConstants.ADD_NOTIFICATION_TEMPLATES_SUCCESS:
            return {
                ...state,
                add_notification_template_status: statusResponse.SUCCESS
            };
        case notificationsConstants.ADD_NOTIFICATION_TEMPLATES_FAILURE:
            return {
                ...state,
                add_notification_template_status: statusResponse.FAILURE
            };
        case notificationsConstants.EDIT_NOTIFICATION_TEMPLATES_REQUEST:
            return {
                ...state,
                edit_notification_template_status: statusResponse.IN_PROGRESS
            };
        case notificationsConstants.EDIT_NOTIFICATION_TEMPLATES_SUCCESS:
            return {
                ...state,
                edit_notification_template_status: statusResponse.SUCCESS
            };
        case notificationsConstants.EDIT_NOTIFICATION_TEMPLATES_FAILURE:
            return {
                ...state,
                edit_notification_template_status: statusResponse.FAILURE
            };
        case notificationsConstants.GET_TEMPLATE_TYPE_LIST_REQUEST:
            return {
                ...state,
                get_template_type_list: statusResponse.IN_PROGRESS,
                templateTypeList: []
            };
        case notificationsConstants.GET_TEMPLATE_TYPE_LIST_SUCCESS:
            return {
                ...state,
                get_template_type_list: statusResponse.SUCCESS,
                templateTypeList: action.data
            };
        case notificationsConstants.GET_TEMPLATE_TYPE_LIST_FAILURE:
            return {
                ...state,
                get_template_type_list: statusResponse.FAILURE,
                templateTypeList: []
            };
        case notificationsConstants.SUBMIT_PUSH_NOTIFICATIONS_TO_USERS_REQUEST:
            return {
                ...state,
                submit_push_notifications_to_users_status: statusResponse.IN_PROGRESS,
            };
        case notificationsConstants.SUBMIT_PUSH_NOTIFICATIONS_TO_USERS_SUCCESS:
            return {
                ...state,
                submit_push_notifications_to_users_status: statusResponse.SUCCESS,
            };
        case notificationsConstants.SUBMIT_PUSH_NOTIFICATIONS_TO_USERS_FAILURE:
            return {
                ...state,
                submit_push_notifications_to_users_status: statusResponse.FAILURE,
            };
        case notificationsConstants.SEND_EMAIL_REQUEST:
            return {
                ...state,
                send_email_status: statusResponse.IN_PROGRESS,
            };
        case notificationsConstants.SEND_EMAIL_SUCCESS:
            return {
                ...state,
                send_email_status: statusResponse.SUCCESS,
            };
        case notificationsConstants.SEND_EMAIL_FAILURE:
            return {
                ...state,
                send_email_status: statusResponse.FAILURE,
            };
        case notificationsConstants.CUSTOM_NOTIFICATIONS_TO_USERS_REQUEST:
            return {
                ...state,
                custom_notifications_to_users_status: statusResponse.IN_PROGRESS,
            };
        case notificationsConstants.CUSTOM_NOTIFICATIONS_TO_USERS_SUCCESS:
            return {
                ...state,
                custom_notifications_to_users_status: statusResponse.SUCCESS,
            };
        case notificationsConstants.CUSTOM_NOTIFICATIONS_TO_USERS_FAILURE:
            return {
                ...state,
                custom_notifications_to_users_status: statusResponse.FAILURE,
            };
        case notificationsConstants.DELETE_NOTOFICATION_REQUEST:
            return {
                ...state,
                delete_notofication_status: statusResponse.IN_PROGRESS,
            };
        case notificationsConstants.DELETE_NOTOFICATION_SUCCESS:
            return {
                ...state,
                delete_notofication_status: statusResponse.SUCCESS,
            };
        case notificationsConstants.DELETE_NOTOFICATION_FAILURE:
            return {
                ...state,
                delete_notofication_status: statusResponse.FAILURE,
            };
        default:
            return state;
    }
}