export const testersConstant = {
    GET_TESTERS_REQUEST: 'GET_TESTERS_REQUEST',
    GET_TESTERS_SUCCESS: 'GET_TESTERS_SUCCESS',
    GET_TESTERS_FAILURE: 'GET_TESTERS_FAILURE',

    FILTER_TESTERS_REQUEST: 'FILTER_TESTERS_REQUEST',
    FILTER_TESTERS_SUCCESS: 'FILTER_TESTERS_SUCCESS',
    FILTER_TESTERS_FAILURE: 'FILTER_TESTERS_FAILURE',

    DELETE_TESTER_REQUEST: 'DELETE_TESTER_REQUEST',
    DELETE_TESTER_SUCCESS: 'DELETE_TESTER_SUCCESS',
    DELETE_TESTER_FAILURE: 'DELETE_TESTER_FAILURE',

    CHANGE_TESTER_STATUS_REQUEST: 'CHANGE_TESTER_STATUS_REQUEST',
    CHANGE_TESTER_STATUS_SUCCESS: 'CHANGE_TESTER_STATUS_SUCCESS',
    CHANGE_TESTER_STATUS_FAILURE: 'CHANGE_TESTER_STATUS_FAILURE',
};