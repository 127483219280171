import { educationTagsConstant } from '../_constants';

let initialState = {
    educationTags: []
};

export function educationTags(state = initialState, action) {
    switch (action.type) {
        case educationTagsConstant.SET_EDUCATION_TAGS:
            return {
                ...state,
                educationTags: action.data
            };
        default:
            return state;
    }
}