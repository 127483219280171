export const questionsConstants = {
    ADD_ONBOARDING_QUESTIONS_REQUEST: "ADD_ONBOARDING_QUESTIONS_REQUEST",
    ADD_ONBOARDING_QUESTIONS_SUCCESS: "ADD_ONBOARDING_QUESTIONS_SUCCESS",
    ADD_ONBOARDING_QUESTIONS_FAILURE: "ADD_ONBOARDING_QUESTIONS_FAILURE",

    EDIT_ONBOARDING_QUESTIONS_REQUEST: "EDIT_ONBOARDING_QUESTIONS_REQUEST",
    EDIT_ONBOARDING_QUESTIONS_SUCCESS: "EDIT_ONBOARDING_QUESTIONS_SUCCESS",
    EDIT_ONBOARDING_QUESTIONS_FAILURE: "EDIT_ONBOARDING_QUESTIONS_FAILURE",

    GET_ONBOARDING_QUESTIONS_REQUEST: "GET_ONBOARDING_QUESTIONS_REQUEST",
    GET_ONBOARDING_QUESTIONS_SUCCESS: "GET_ONBOARDING_QUESTIONS_SUCCESS",
    GET_ONBOARDING_QUESTIONS_FAILURE: "GET_ONBOARDING_QUESTIONS_FAILURE",

    DELETE_ONBOARDING_QUESTIONS_REQUEST: "DELETE_ONBOARDING_QUESTIONS_REQUEST",
    DELETE_ONBOARDING_QUESTIONS_SUCCESS: "DELETE_ONBOARDING_QUESTIONS_SUCCESS",
    DELETE_ONBOARDING_QUESTIONS_FAILURE: "DELETE_ONBOARDING_QUESTIONS_FAILURE",

    SET_QUESTION_ORDER_REQUEST: "SET_QUESTION_ORDER_REQUEST",
    SET_QUESTION_ORDER_SUCCESS: "SET_QUESTION_ORDER_SUCCESS",
    SET_QUESTION_ORDER_FAILURE: "SET_QUESTION_ORDER_FAILURE",
};