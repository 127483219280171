export const dietaryTagsConstant = {
    SET_DIETARY_TAGS: "SET_DIETARY_TAGS",

    GET_DIETARY_TAGS_REQUEST: "GET_DIETARY_TAGS_REQUEST",
    GET_DIETARY_TAGS_SUCCESS: "GET_DIETARY_TAGS_SUCCESS",
    GET_DIETARY_TAGS_FAILURE: "GET_DIETARY_TAGS_FAILURE",

    GET_CRITERIA_TAGS_REQUEST: "GET_CRITERIA_TAGS_REQUEST",
    GET_CRITERIA_TAGS_SUCCESS: "GET_CRITERIA_TAGS_SUCCESS",
    GET_CRITERIA_TAGS_FAILURE: "GET_CRITERIA_TAGS_FAILURE",

    EDIT_CRITERIA_TAG_REQUEST: "EDIT_CRITERIA_TAG_REQUEST",
    EDIT_CRITERIA_TAG_SUCCESS: "EDIT_CRITERIA_TAG_SUCCESS",
    EDIT_CRITERIA_TAG_FAILURE: "EDIT_CRITERIA_TAG_FAILURE",

    ADD_CRITERIA_TAG_REQUEST: "ADD_CRITERIA_TAG_REQUEST",
    ADD_CRITERIA_TAG_SUCCESS: "ADD_CRITERIA_TAG_SUCCESS",
    ADD_CRITERIA_TAG_FAILURE: "ADD_CRITERIA_TAG_FAILURE",
};