import { allPaymentsConstant, statusResponse } from "../_constants";

export function allPayments(state = {}, action) {
  console.log(action);
  switch (action.type) {
    case allPaymentsConstant.GET_ALL_PAYMENTS_REQUEST:
      return {
        ...state,
        all_paymetns_list: statusResponse.IN_PROGRESS,
        allPaymentsList: null,
      };
    case allPaymentsConstant.GET_ALL_PAYMENTS_SUCCESS:
      return {
        ...state,
        all_paymetns_list: statusResponse.SUCCESS,
        allPaymentsList: action.data,
      };
    case allPaymentsConstant.GET_ALL_PAYMENTS_FAILURE:
      return {
        ...state,
        all_paymetns_list: statusResponse.FAILURE,
        allPaymentsList: null,
      };
    default:
      return state;
  }
}
