export const versionConstants = {
    GET_VERSION_REQUEST: 'GET_VERSION_REQUEST',
    GET_VERSION_SUCCESS: 'GET_VERSION_SUCCESS',
    GET_VERSION_FAILURE: 'GET_VERSION_FAILURE',

    UPDATE_VERSION_REQUEST: 'UPDATE_VERSION_REQUEST',
    UPDATE_VERSION_SUCCESS: 'UPDATE_VERSION_SUCCESS',
    UPDATE_VERSION_FAILURE: 'UPDATE_VERSION_FAILURE',

    GET_BANNERS_REQUEST: 'GET_BANNERS_REQUEST',
    GET_BANNERS_SUCCESS: 'GET_BANNERS_SUCCESS',
    GET_BANNERS_FAILURE: 'GET_BANNERS_FAILURE',

    ADD_BANNERS_REQUEST: "ADD_BANNERS_REQUEST",
    ADD_BANNERS_SUCCESS: "ADD_BANNERS_SUCCESS",
    ADD_BANNERS_FAILURE: "ADD_BANNERS_FAILURE",

    EDIT_BANNERS_REQUEST: "EDIT_BANNERS_REQUEST",
    EDIT_BANNERS_SUCCESS: "EDIT_BANNERS_SUCCESS",
    EDIT_BANNERS_FAILURE: "EDIT_BANNERS_FAILURE",

    DELETE_BANNER_REQUEST: "DELETE_BANNER_REQUEST",
    DELETE_BANNER_SUCCESS: "DELETE_BANNER_SUCCESS",
    DELETE_BANNER_FAILURE: "DELETE_BANNER_FAILURE",

    SET_BANNERS_ORDER_REQUEST: "SET_BANNERS_ORDER_REQUEST",
    SET_BANNERS_ORDER_SUCCESS: "SET_BANNERS_ORDER_SUCCESS",
    SET_BANNERS_ORDER_FAILURE: "SET_BANNERS_ORDER_FAILURE",

    GET_BANNER_IMAGE_REQUEST: "GET_BANNER_IMAGE_REQUEST",
    GET_BANNER_IMAGE_SUCCESS: "GET_BANNER_IMAGE_SUCCESS",
    GET_BANNER_IMAGE_FAILURE: "GET_BANNER_IMAGE_FAILURE",

    DELETE_BANNER_IMAGE_REQUEST: "DELETE_BANNER_IMAGE_REQUEST",
    DELETE_BANNER_IMAGE_SUCCESS: "DELETE_BANNER_IMAGE_SUCCESS",
    DELETE_BANNER_IMAGE_FAILURE: "DELETE_BANNER_IMAGE_FAILURE",
};