import { algorithemConstants, statusResponse } from '../_constants';

export function algorithem(state = {}, action) {
    switch (action.type) {
        case algorithemConstants.GET_ALGO_DETAIL_REQUEST:
            return {
                ...state,
                get_algo_list_status: statusResponse.IN_PROGRESS,
                testAlgoList: action.data
            };
        case algorithemConstants.GET_ALGO_DETAIL_SUCCESS:
            return {
                ...state,
                get_algo_list_status: statusResponse.SUCCESS,
                testAlgoList: action.data
            };
        case algorithemConstants.GET_ALGO_DETAIL_FAILURE:
            return {
                ...state,
                get_algo_list_status: statusResponse.FAILURE,
                testAlgoList: null
            };

        case algorithemConstants.GET_F1_USER_REQUEST:
            return {
                ...state,
                get_algo_list_status: statusResponse.IN_PROGRESS,
                testAlgoList: action.data
            };
        case algorithemConstants.GET_F1_USER_SUCCESS:
            return {
                ...state,
                get_algo_list_status: statusResponse.SUCCESS,
                testAlgoList: action.data
            };
        case algorithemConstants.GET_F1_USER_FAILURE:
            return {
                ...state,
                get_algo_list_status: statusResponse.FAILURE,
                testAlgoList: null
            };
        case algorithemConstants.GET_F2_USER_REQUEST:
            return {
                ...state,
                get_f2_user_status: statusResponse.IN_PROGRESS,
                testF2List: action.data
            };
        case algorithemConstants.GET_F2_USER_SUCCESS:
            return {
                ...state,
                get_f2_user_status: statusResponse.SUCCESS,
                testF2List: action.data
            };
        case algorithemConstants.GET_F2_USER_FAILURE:
            return {
                ...state,
                get_f2_user_status: statusResponse.FAILURE,
                testF2List: null
            };
        case algorithemConstants.GET_PARTICIPATE_USER_REQUEST:
            return {
                ...state,
                get_participate_user_status: statusResponse.IN_PROGRESS,
                participate_list: action.data
            };
        case algorithemConstants.GET_PARTICIPATE_USER_SUCCESS:
            return {
                ...state,
                get_participate_user_status: statusResponse.SUCCESS,
                participate_list: action.data
            };
        case algorithemConstants.GET_PARTICIPATE_USER_FAILURE:
            return {
                ...state,
                get_participate_user_status: statusResponse.FAILURE,
                participate_list: null
            };
        case algorithemConstants.DELETE_PARTICIPATE_USER_REQUEST:
            return {
                ...state,
                delete_participate_status: statusResponse.IN_PROGRESS,
                delete_participate_res: action.data
            };
        case algorithemConstants.DELETE_PARTICIPATE_USER_SUCCESS:
            return {
                ...state,
                delete_participate_status: statusResponse.SUCCESS,
                delete_participate_res: action.data
            };
        case algorithemConstants.DELETE_PARTICIPATE_USER_FAILURE:
            return {
                ...state,
                delete_participate_status: statusResponse.FAILURE,
                delete_participate_res: null
            };
        default:
            return state;
    }
}