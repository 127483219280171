import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { renderRoutes } from 'react-router-config';

import './assets/scss/app.scss';
import { localStorageEnc } from './_utilities';
import { history } from './_helpers';
import { config } from './config';
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));
const Login = React.lazy(() => import('./Login'));
const Register = React.lazy(() => import('./PreLogin/Register'));
const ForgotPassword = React.lazy(() => import('./PreLogin/ForgotPassword'));
const ResetPassword = React.lazy(() => import('./PreLogin/ResetPassword'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;
class App extends Component {

  render() {
    return (
      <React.Fragment>
        <Router history={history} basename={config.basePath}>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
              <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
              <Route exact path="/forgotpassword" name="Register Page" render={props => <ForgotPassword {...props} />} />
              <Route exact path="/resetpassword" name="Reset Password Page" render={props => <ResetPassword {...props} />} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
              {/* 
               Remove commented code while integrating apis
              <Route path="/" name="Home"
                render={props =>
                  localStorageEnc.getItem("user") ?
                    <DefaultLayout {...props} /> :
                    <Redirect
                      to={{ pathname: "login" }}
                    />
                }
              /> */}

              <Route path="/" name="Home"
                render={props =>
                  <DefaultLayout {...props} />
                }
              />
            </Switch>
          </React.Suspense>
        </Router>
        <ToastContainer enableMultiContainer containerId={'TOP_RIGHT'} position={toast.POSITION.TOP_RIGHT} />
        <ToastContainer enableMultiContainer containerId={'BOTTOM_RIGHT'} position={toast.POSITION.BOTTOM_RIGHT} />
      </React.Fragment>
    );
  }
}

export default App;
