export const currencyDetailsConstant = {
    GET_CURRENCY_DETAILS_REQUEST: "GET_CURRENCY_DETAILS_REQUEST",
    GET_CURRENCY_DETAILS_SUCCESS: "GET_CURRENCY_DETAILS_SUCCESS",
    GET_CURRENCY_DETAILS_FAILURE: "GET_CURRENCY_DETAILS_FAILURE",

    DELETE_CURRENCY_DETAILS_REQUEST: "DELETE_CURRENCY_DETAILS_REQUEST",
    DELETE_CURRENCY_DETAILS_SUCCESS: "DELETE_CURRENCY_DETAILS_SUCCESS",
    DELETE_CURRENCY_DETAILS_FAILURE: "DELETE_CURRENCY_DETAILS_FAILURE",

    EDIT_CURRENCY_DETAILS_REQUEST: "EDIT_CURRENCY_DETAILS_REQUEST",
    EDIT_CURRENCY_DETAILS_SUCCESS: "EDIT_CURRENCY_DETAILS_SUCCESS",
    EDIT_CURRENCY_DETAILS_FAILURE: "EDIT_CURRENCY_DETAILS_FAILURE",

    ADD_CURRENCY_DETAILS_REQUEST: "ADD_CURRENCY_DETAILS_REQUEST",
    ADD_CURRENCY_DETAILS_SUCCESS: "ADD_CURRENCY_DETAILS_SUCCESS",
    ADD_CURRENCY_DETAILS_FAILURE: "ADD_CURRENCY_DETAILS_FAILURE",
};