import { galleryConstant } from '../_constants';

let initialState = {
    gallery: []
};

export function gallery(state = initialState, action){
    switch(action.type){
        case galleryConstant.SET_GALLERY:
            return {
                ...state,
                gallery: action.data
            };
        default:
            return state;
    }
}