import { testListConstants, userConstants } from '../_constants';

let initialState = {
    test: [],
    totalDocs: 0,
    totalPages: 0,
    currentPage: 1,
    limit: 10   ,
    paginationCounter: [1],
    searchText: ""
};

export function testList(state = initialState, action){
    console.log(action)
    switch(action.type){
        case testListConstants.HANDLE_TEST_LIST:
            return {
                ...state,
                ...action.data
            };
        case testListConstants.SET_TEST_LIST:
            return {
                ...state,
                organization: action.data
            };
        case testListConstants.SET_TEST_TOTAL_PAGE:
            return {
                ...state,
                totalPages: action.data
            };
        case testListConstants.SET_TEST_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.data
            };
        case testListConstants.SET_TEST_SEARCH_TEXT:
            return {
                ...state,
                searchText: action.data
            }
        case userConstants.USER_LOGOUT:
            return initialState;
        default:
            return state;
    }
}