import { questionsConstants, statusResponse } from '../_constants';

export function questions(state = {}, action) {
    switch (action.type) {
        case questionsConstants.ADD_ONBOARDING_QUESTIONS_REQUEST:
            return {
                ...state,
                add_question_status: statusResponse.IN_PROGRESS,
                addedQuestionData: action.data
            };
        case questionsConstants.ADD_ONBOARDING_QUESTIONS_SUCCESS:
            return {
                ...state,
                add_question_status: statusResponse.SUCCESS,
                addedQuestionData: action.data
            };
        case questionsConstants.ADD_ONBOARDING_QUESTIONS_FAILURE:
            return {
                ...state,
                add_question_status: statusResponse.FAILURE,
                addedQuestionData: null
            };
        case questionsConstants.EDIT_ONBOARDING_QUESTIONS_REQUEST:
            return {
                ...state,
                edit_question_status: statusResponse.IN_PROGRESS,
            };
        case questionsConstants.EDIT_ONBOARDING_QUESTIONS_SUCCESS:
            return {
                ...state,
                edit_question_status: statusResponse.SUCCESS,
            };
        case questionsConstants.EDIT_ONBOARDING_QUESTIONS_FAILURE:
            return {
                ...state,
                edit_question_status: statusResponse.FAILURE,
            };
        case questionsConstants.GET_ONBOARDING_QUESTIONS_REQUEST:
            return {
                ...state,
                get_question_status: statusResponse.IN_PROGRESS,
                questionsList: action.data
            };
        case questionsConstants.GET_ONBOARDING_QUESTIONS_SUCCESS:
            return {
                ...state,
                get_question_status: statusResponse.SUCCESS,
                questionsList: action.data
            };
        case questionsConstants.GET_ONBOARDING_QUESTIONS_FAILURE:
            return {
                ...state,
                get_question_status: statusResponse.FAILURE,
                questionsList: null
            };
        case questionsConstants.DELETE_ONBOARDING_QUESTIONS_REQUEST:
            return {
                ...state,
                delete_question_status: statusResponse.IN_PROGRESS,
                deletedQuestionData: action.data
            };
        case questionsConstants.DELETE_ONBOARDING_QUESTIONS_SUCCESS:
            return {
                ...state,
                delete_question_status: statusResponse.SUCCESS,
                deletedQuestionData: action.data
            };
        case questionsConstants.DELETE_ONBOARDING_QUESTIONS_FAILURE:
            return {
                ...state,
                delete_question_status: statusResponse.FAILURE,
                deletedQuestionData: null
            };
        case questionsConstants.SET_QUESTION_ORDER_REQUEST:
            return {
                ...state,
                set_question_order_status: statusResponse.IN_PROGRESS,
                orderedQuestions: action.data
            };
        case questionsConstants.SET_QUESTION_ORDER_SUCCESS:
            return {
                ...state,
                set_question_order_status: statusResponse.SUCCESS,
                orderedQuestions: action.data
            };
        case questionsConstants.SET_QUESTION_ORDER_FAILURE:
            return {
                ...state,
                set_question_order_status: statusResponse.FAILURE,
                orderedQuestions: null
            };
        default:
            return state;
    }
}