import { incomeTagsConstant } from '../_constants';

let initialState = {
    incomeTags: []
};

export function incomeTags(state = initialState, action) {
    switch (action.type) {
        case incomeTagsConstant.SET_INCOME_TAGS:
            return {
                ...state,
                incomeTags: action.data
            };
        default:
            return state;
    }
}