import { versionConstants, statusResponse } from '../_constants';

let initialState = {
    bannerList: []
};

export function version(state = initialState, action) {
    switch (action.type) {
        case versionConstants.GET_VERSION_REQUEST:
            return {
                ...state,
                get_version_status: statusResponse.IN_PROGRESS,
                versionData: action.data
            };
        case versionConstants.GET_VERSION_SUCCESS:
            return {
                ...state,
                get_version_status: statusResponse.SUCCESS,
                versionData: action.data
            };
        case versionConstants.GET_VERSION_FAILURE:
            return {
                ...state,
                get_version_status: statusResponse.FAILURE,
                versionData: null
            };
        case versionConstants.UPDATE_VERSION_REQUEST:
            return {
                ...state,
                update_version_status: statusResponse.IN_PROGRESS,
            };
        case versionConstants.UPDATE_VERSION_SUCCESS:
            return {
                ...state,
                update_version_status: statusResponse.SUCCESS,
            };
        case versionConstants.UPDATE_VERSION_FAILURE:
            return {
                ...state,
                update_version_status: statusResponse.FAILURE,
            };
        case versionConstants.GET_BANNERS_REQUEST:
            return {
                ...state,
                bannerList: action.data,
                get_banners_status: statusResponse.IN_PROGRESS,
            };
        case versionConstants.GET_BANNERS_SUCCESS:
            return {
                ...state,
                bannerList: action.data,
                get_banners_status: statusResponse.SUCCESS,
            };
        case versionConstants.GET_BANNERS_FAILURE:
            return {
                ...state,
                bannerList: action.data,
                get_banners_status: statusResponse.FAILURE,
            };
        case versionConstants.ADD_BANNERS_REQUEST:
            return {
                ...state,
                add_banner_status: statusResponse.IN_PROGRESS
            };
        case versionConstants.ADD_BANNERS_SUCCESS:
            return {
                ...state,
                add_banner_status: statusResponse.SUCCESS
            };
        case versionConstants.ADD_BANNERS_FAILURE:
            return {
                ...state,
                add_banner_status: statusResponse.FAILURE
            };
        case versionConstants.EDIT_BANNERS_REQUEST:
            return {
                ...state,
                edit_banner_status: statusResponse.IN_PROGRESS
            };
        case versionConstants.EDIT_BANNERS_SUCCESS:
            return {
                ...state,
                edit_banner_status: statusResponse.SUCCESS
            };
        case versionConstants.EDIT_BANNERS_FAILURE:
            return {
                ...state,
                edit_banner_status: statusResponse.FAILURE
            };
        case versionConstants.DELETE_BANNER_REQUEST:
            return {
                ...state,
                delete_banner_status: statusResponse.IN_PROGRESS,
            };
        case versionConstants.DELETE_BANNER_SUCCESS:
            return {
                ...state,
                delete_banner_status: statusResponse.SUCCESS,
            };
        case versionConstants.DELETE_BANNER_FAILURE:
            return {
                ...state,
                delete_banner_status: statusResponse.FAILURE,
            };
        case versionConstants.SET_BANNERS_ORDER_REQUEST:
            return {
                ...state,
                get_banners_order_status: statusResponse.IN_PROGRESS,
            };
        case versionConstants.SET_BANNERS_ORDER_SUCCESS:
            return {
                ...state,
                get_banners_order_status: statusResponse.SUCCESS,
            };
        case versionConstants.SET_BANNERS_ORDER_FAILURE:
            return {
                ...state,
                get_banners_order_status: statusResponse.FAILURE,
            };
        case versionConstants.GET_BANNER_IMAGE_REQUEST:
            return {
                ...state,
                get_banners_image_status: statusResponse.IN_PROGRESS,
            };
        case versionConstants.GET_BANNER_IMAGE_SUCCESS:
            return {
                ...state,
                bannerImageList: action.data,
                get_banners_image_status: statusResponse.SUCCESS,
            };
        case versionConstants.GET_BANNER_IMAGE_FAILURE:
            return {
                ...state,
                get_banners_image_status: statusResponse.FAILURE,
            };
        case versionConstants.DELETE_BANNER_IMAGE_REQUEST:
            return {
                ...state,
                delete_banners_image_status: statusResponse.IN_PROGRESS,
            };
        case versionConstants.DELETE_BANNER_IMAGE_SUCCESS:
            return {
                ...state,
                delete_banners_image_status: statusResponse.SUCCESS,
            };
        case versionConstants.DELETE_BANNER_IMAGE_FAILURE:
            return {
                ...state,
                delete_banners_image_status: statusResponse.FAILURE,
            };
        default:
            return state;
    }
}