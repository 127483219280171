import { localStorageEnc } from '../_utilities';

export const commonFunctions = {
    getRequestOptions,
    validateEmail,
    validateNumeric,
    validatePostCode,
    validateMobile,
    validateDecimal,
    convertNumberToDecimalPoint,
    imageToFile,
    logout,
    getAccessToken,
    getFileName,
    convertDateToString,
    getJsonFromUrl
};

function getRequestOptions(type, extraHeaders, body, bNoToken) {
    let authHeader = {};
    if (!bNoToken) {
        authHeader = {
            Authorization: `Bearer ${localStorageEnc.getItem("tryio-admin-token")}`
        }
    }

    let requestOptions = {
        method: type,
        headers: {
            ...extraHeaders,
            ...authHeader
        }
    };
    if (body && type !== "GET" && type !== "DELETE") {
        requestOptions['body'] = body;
    }
    return requestOptions;
}

function getAccessToken() {
    return localStorageEnc.getItem("tryio-admin-token")
}

function validateEmail(mail) {
    if (mail) {
        return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail);
    }
    return false;
}

function validateNumeric(number) {
    return /^\d+$/.test(number);
}

function validatePostCode(postCode) {
    if (postCode) {
        return /^[1-9][0-9]{5}$/.test(postCode);
    }
    return false;
}

function validateMobile(mobile) {
    if (mobile) {
        return /^[6-9]\d{9}$/.test(mobile);
    }
    return false;
}

function validateDecimal(number) {
    return /^\d+(\.\d+|\d*)$/.test(number);
}

function convertNumberToDecimalPoint(number) {
    let regex = /^\d+(\.\d{0,2})?$/;
    if (!regex.test(number)) {
        let retVal = parseFloat(number);
        if (!isNaN(retVal)) {
            retVal = parseFloat(Math.round(number * 100) / 100).toFixed(2);
        } else {
            retVal = "";
        }
        return isNaN(retVal) ? "" : retVal;
    }
    return number;
}

function imageToFile(image, fileName, cb) {
    let canvas = document.createElement('canvas');
    canvas.width = image.clientWidth;
    canvas.height = image.clientHeight;
    let context = canvas.getContext('2d');
    context.drawImage(image, 0, 0);
    canvas.toBlob(function (blob) {
        blob.lastModifiedDate = new Date();
        blob.name = fileName;
        return cb(blob);
    }, 'image/png');
}

function convertDateToString(dateObj) {
    if (dateObj) {
        let month = (dateObj.getMonth() + 1).toString();
        month = month.length === 1 ? '0' + month : month;
        let date = dateObj.getDate().toString();
        date = date.length === 1 ? '0' + date : date;
        let year = dateObj.getFullYear().toString();
        return `${year}-${month}-${date}`;
    }
    return "";
}

function getFileName(header, type) {
    let fileName = 'downloaded.' + type;
    if (header) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(header);
        if (matches != null && matches[1]) { 
            fileName = matches[1].replace(/['"]/g, '');
        }
    }
    return fileName;
}

function getJsonFromUrl(url) {
    var result = {};
    if (url) {
        console.log(url);
        var query = url.substr(1);
        console.log(query);
        query.split("&").forEach(function (part) {
            var item = part.split("=");
            result[item[0]] = decodeURIComponent(item[1]);
        });
    }
    return result;
}

function logout() {
    localStorageEnc.removeItem("tryio-admin-token");
    localStorageEnc.removeItem("tryio-admin-user");
}