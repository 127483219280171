import { dietaryTagsConstant, statusResponse } from '../_constants';

let initialState = {
    dietaryTags: []
};

export function dietaryTags(state = initialState, action) {
    switch (action.type) {
        case dietaryTagsConstant.SET_DIETARY_TAGS:
            return {
                ...state,
                dietaryTags: action.data
            };
        case dietaryTagsConstant.GET_DIETARY_TAGS_REQUEST:
            return {
                ...state,
                dietaryTagsList: action.data,
                get_dietary_tags_status: statusResponse.IN_PROGRESS
            };
        case dietaryTagsConstant.GET_DIETARY_TAGS_SUCCESS:
            return {
                ...state,
                dietaryTagsList: action.data,
                get_dietary_tags_status: statusResponse.SUCCESS
            };
        case dietaryTagsConstant.GET_DIETARY_TAGS_FAILURE:
            return {
                ...state,
                dietaryTagsList: action.data,
                get_dietary_tags_status: statusResponse.FAILURE
            };
        case dietaryTagsConstant.GET_CRITERIA_TAGS_REQUEST:
            return {
                ...state,
                criteriaTagsList: action.data,
                get_criteria_tags_status: statusResponse.IN_PROGRESS
            };
        case dietaryTagsConstant.GET_CRITERIA_TAGS_SUCCESS:
            return {
                ...state,
                criteriaTagsList: action.data,
                get_criteria_tags_status: statusResponse.SUCCESS
            };
        case dietaryTagsConstant.GET_CRITERIA_TAGS_FAILURE:
            return {
                ...state,
                criteriaTagsList: action.data,
                get_criteria_tags_status: statusResponse.FAILURE
            };
        case dietaryTagsConstant.EDIT_CRITERIA_TAG_REQUEST:
            return {
                ...state,
                editedCriteriaTag: action.data,
                edit_criteria_tag_status: statusResponse.IN_PROGRESS
            };
        case dietaryTagsConstant.EDIT_CRITERIA_TAG_SUCCESS:
            return {
                ...state,
                editedCriteriaTag: action.data,
                edit_criteria_tag_status: statusResponse.SUCCESS
            };
        case dietaryTagsConstant.EDIT_CRITERIA_TAG_FAILURE:
            return {
                ...state,
                editedCriteriaTag: action.data,
                edit_criteria_tag_status: statusResponse.FAILURE
            };
        case dietaryTagsConstant.ADD_CRITERIA_TAG_REQUEST:
            return {
                ...state,
                addedCriteriaTag: action.data,
                add_criteria_tag_status: statusResponse.IN_PROGRESS
            };
        case dietaryTagsConstant.ADD_CRITERIA_TAG_SUCCESS:
            return {
                ...state,
                addedCriteriaTag: action.data,
                add_criteria_tag_status: statusResponse.SUCCESS
            };
        case dietaryTagsConstant.ADD_CRITERIA_TAG_FAILURE:
            return {
                ...state,
                addedCriteriaTag: action.data,
                add_criteria_tag_status: statusResponse.FAILURE
            };
        default:
            return state;
    }
}