export const config = {
  basePath: "/",
  printSummaryURL:
    process.env.NODE_ENV === "development"
      ? "https://dev.appcommon.tryio.no"
      : "https://appcommon.tryio.no",
  apiUrl:
    process.env.NODE_ENV === "development"
      ? "http://62.72.13.79:5001/api/admin"
      : "http://62.72.13.79:5001/api/admin",
  apiUrlForBusiness:
    process.env.NODE_ENV === "development"
      ? "https://dev.businessbackend.tryio.no"
      : "https://businessbackend.tryio.no",
  printPage:
    process.env.NODE_ENV === "development"
      ? "https://dev.business.tryio.no/"
      : "https://business.tryio.no/",
  apiForBackup: "http://13.49.237.97:4040",
};
