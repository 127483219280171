import { combineReducers } from 'redux';

import { user } from './user.reducers';
import { organization } from './organization.reducers';
import { language } from "./language.reducer";
import { dietaryTags } from "./dietaryTags.reducer";
import { blockedDomain } from "./blockedlistDomain.reducer";
import { gallery } from "./gallery.reducer";
import { questions } from './questions.reducer';
import { test } from './test.reducer';
import { userConstants } from '../_constants';
import { testers } from "./testers.reducers";
import { testList } from "./testList.reducer";
import { f1Users } from "./f1Users.reducer";
import { f2Users } from "./f2Users.reducer";
import { educationTags } from "./educationTags.reducer";
import { incomeTags } from "./incomeTags.reducer";
import { familySituationTags } from "./familySituationTags.reducer";
import { notifications } from "./notifications.reducer";
import { questionnaire } from './questionnaire.reducers';
import { location } from './location.reducer';
import { version } from './version.reducer';
import { allPayments } from "./allpayments.reducers";
import { currencyDetails } from "./currencyDetails.reducer";
import { algorithem } from "./algorithem.reducer";

const appReducers = combineReducers({
  user,
  organization,
  language,
  dietaryTags,
  blockedDomain,
  gallery,
  questions,
  testers,
  test,
  testList,
  f1Users,
  f2Users,
  educationTags,
  incomeTags,
  familySituationTags,
  notifications,
  questionnaire,
  location,
  version,
  allPayments,
  currencyDetails,
  algorithem
});

const rootReducer = (state, action) => {
  if (action.type === userConstants.USER_LOGOUT) {
    state = undefined;
  }
  return appReducers(state, action);
};

export default rootReducer;
