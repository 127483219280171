import { familySituationTagsConstant } from '../_constants';

let initialState = {
    houseHoldSize: [],
    children: [],
    ID: ""
};

export function familySituationTags(state = initialState, action) {
    switch (action.type) {
        case familySituationTagsConstant.SET_FAMILY_SITUATION_TAGS:
            return {
                ...state,
                ...action.data
            };
        default:
            return state;
    }
}