export const testConstants = {
    GET_TEST_REQUEST: "GET_TEST_REQUEST",
    GET_TEST_SUCCESS: "GET_TEST_SUCCESS",
    GET_TEST_FAILURE: "GET_TEST_FAILURE",

    DELETE_TEST_REQUEST: "DELETE_TEST_REQUEST",
    DELETE_TEST_SUCCESS: "DELETE_TEST_SUCCESS",
    DELETE_TEST_FAILURE: "DELETE_TEST_FAILURE",

    UPDATE_TEST_REQUEST: "UPDATE_TEST_REQUEST",
    UPDATE_TEST_SUCCESS: "UPDATE_TEST_SUCCESS",
    UPDATE_TEST_FAILURE: "UPDATE_TEST_FAILURE",

    GET_TEST_LIST_REQUEST: "GET_TEST_LIST_REQUEST",
    GET_TEST_LIST_SUCCESS: "GET_TEST_LIST_SUCCESS",
    GET_TEST_LIST_FAILURE: "GET_TEST_LIST_FAILURE",

    GET_FAMILY_SITUATION_REQUEST: "GET_FAMILY_SITUATION_REQUEST",
    GET_FAMILY_SITUATION_SUCCESS: "GET_FAMILY_SITUATION_SUCCESS",
    GET_FAMILY_SITUATION_FAILURE: "GET_FAMILY_SITUATION_FAILURE",

    GET_EDUCATION_LEVEL_REQUEST: "GET_EDUCATION_LEVEL_REQUEST",
    GET_EDUCATION_LEVEL_SUCCESS: "GET_EDUCATION_LEVEL_SUCCESS",
    GET_EDUCATION_LEVEL_FAILURE: "GET_EDUCATION_LEVEL_FAILURE",

    GET_INCOME_LEVEL_REQUEST: "GET_INCOME_LEVEL_REQUEST",
    GET_INCOME_LEVEL_SUCCESS: "GET_INCOME_LEVEL_SUCCESS",
    GET_INCOME_LEVEL_FAILURE: "GET_INCOME_LEVEL_FAILURE",

    GET_CRON_JOB_DATA_REQUEST: "GET_CRON_JOB_DATA_REQUEST",
    GET_CRON_JOB_DATA_SUCCESS: "GET_CRON_JOB_DATA_SUCCESS",
    GET_CRON_JOB_DATA_FAILURE: "GET_CRON_JOB_DATA_FAILURE",

    SET_CRON_JOB_DATA_REQUEST: "SET_CRON_JOB_DATA_REQUEST",
    SET_CRON_JOB_DATA_SUCCESS: "SET_CRON_JOB_DATA_SUCCESS",
    SET_CRON_JOB_DATA_FAILURE: "SET_CRON_JOB_DATA_FAILURE",

    GET_TEST_GALLERY_REQUEST: 'GET_TEST_GALLERY_REQUEST',
    GET_TEST_GALLERY_SUCCESS: 'GET_TEST_GALLERY_SUCCESS',
    GET_TEST_GALLERY_FAILURE: 'GET_TEST_GALLERY_FAILURE',

    UPLOAD_GALLERY_IMAGE_REQUEST: 'UPLOAD_GALLERY_IMAGE_REQUEST',
    UPLOAD_GALLERY_IMAGE_SUCCESS: 'UPLOAD_GALLERY_IMAGE_SUCCESS',
    UPLOAD_GALLERY_IMAGE_FAILURE: 'UPLOAD_GALLERY_IMAGE_FAILURE',

    GET_TEMPLATE_REQUEST: 'GET_TEMPLATE_REQUEST',
    GET_TEMPLATE_SUCCESS: 'GET_TEMPLATE_SUCCESS',
    GET_TEMPLATE_FAILURE: 'GET_TEMPLATE_FAILURE',

    SET_TEMPLATE_REQUEST: 'SET_TEMPLATE_REQUEST',
    SET_TEMPLATE_SUCCESS: 'SET_TEMPLATE_SUCCESS',
    SET_TEMPLATE_FAILURE: 'SET_TEMPLATE_FAILURE',

    GET_ALL_QUESTIONNAIRE_REQUEST: 'GET_ALL_QUESTIONNAIRE_REQUEST',
    GET_ALL_QUESTIONNAIRE_SUCCESS: 'GET_ALL_QUESTIONNAIRE_SUCCESS',
    GET_ALL_QUESTIONNAIRE_FAILURE: 'GET_ALL_QUESTIONNAIRE_FAILURE',

    GET_LOCATION_REQUEST: 'GET_LOCATION_REQUEST',
    GET_LOCATION_SUCCESS: 'GET_LOCATION_SUCCESS',
    GET_LOCATION_FAILURE: 'GET_LOCATION_FAILURE',

    UPDATE_VIEW_TEST_SUCCESS: 'UPDATE_VIEW_TEST_SUCCESS',
    UPDATE_VIEW_TEST_FAILURE: 'UPDATE_VIEW_TEST_FAILURE',
    UPDATE_VIEW_TEST_REQUEST: 'UPDATE_VIEW_TEST_REQUEST',

    RUN_ALGORITHM_REQUEST: 'RUN_ALGORITHM_REQUEST',
    RUN_ALGORITHM_SUCCESS: 'RUN_ALGORITHM_SUCCESS',
    RUN_ALGORITHM_FAILURE: 'RUN_ALGORITHM_FAILURE',

    GET_TESTS_LIST_REQUEST: 'GET_TESTS_LIST_REQUEST',
    GET_TESTS_LIST_SUCCESS: 'GET_TESTS_LIST_SUCCESS',
    GET_TESTS_LIST_FAILURE: 'GET_TESTS_LIST_FAILURE',

    FILTER_TESTS_LIST_REQUEST: 'FILTER_TESTS_LIST_REQUEST',
    FILTER_TESTS_LIST_SUCCESS: 'FILTER_TESTS_LIST_SUCCESS',
    FILTER_TESTS_LIST_FAILURE: 'FILTER_TESTS_LIST_FAILURE',

    DELETE_TESTS_LIST_REQUEST: 'DELETE_TESTS_LIST_REQUEST',
    DELETE_TESTS_LIST_SUCCESS: 'DELETE_TESTS_LIST_SUCCESS',
    DELETE_TESTS_LIST_FAILURE: 'DELETE_TESTS_LIST_FAILURE',

    GET_CURRENCY_REQUEST: 'GET_CURRENCY_REQUEST',
    GET_CURRENCY_SUCCESS: 'GET_CURRENCY_SUCCESS',
    GET_CURRENCY_FAILURE: 'GET_CURRENCY_FAILURE'

};