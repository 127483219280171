export const userConstants = {
    USER_LOGOUT: "USER_LOGOUT",
    USER_LOGIN_REQUEST: "USER_LOGIN_REQUEST",
    USER_LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
    USER_LOGIN_FAILURE: "USER_LOGIN_FAILURE",

    REGISTER_USER_REQUEST: 'REGISTER_USER_REQUEST',
    REGISTER_USER_SUCCESS: 'REGISTER_USER_SUCCESS',
    REGISTER_USER_FAILURE: 'REGISTER_USER_FAILURE',

    FORGOT_PASSWORD_REQUEST: 'FORGOT_PASSWORD_REQUEST',
    FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
    FORGOT_PASSWORD_FAILURE: 'FORGOT_PASSWORD_FAILURE',

    RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
    RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
    RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',

    INVITE_USER_REQUEST: 'INVITE_USER_REQUEST',
    INVITE_USER_SUCCESS: 'INVITE_USER_SUCCESS',
    INVITE_USER_FAILURE: 'INVITE_USER_FAILURE',

    VERIFY_ORG_USER_REQUEST: "VERIFY_ORG_USER_REQUEST",
    VERIFY_ORG_USER_SUCCESS: "VERIFY_ORG_USER_SUCCESS",
    VERIFY_ORG_USER_FAILURE: "VERIFY_ORG_USER_FAILURE",

    ACTIVATE_ORG_USER_REQUEST: "ACTIVATE_ORG_USER_REQUEST",
    ACTIVATE_ORG_USER_SUCCESS: "ACTIVATE_ORG_USER_SUCCESS",
    ACTIVATE_ORG_USER_FAILURE: "ACTIVATE_ORG_USER_FAILURE",

    GET_F1_USERS_REQUEST: "GET_F1_USERS_REQUEST",
    GET_F1_USERS_SUCCESS: "GET_F1_USERS_SUCCESS",
    GET_F1_USERS_FAILURE: "GET_F1_USERS_FAILURE",

    GET_F2_USERS_REQUEST: "GET_F2_USERS_REQUEST",
    GET_F2_USERS_SUCCESS: "GET_F2_USERS_SUCCESS",
    GET_F2_USERS_FAILURE: "GET_F2_USERS_FAILURE",

    GET_PARTICIPANTS_USERS_REQUEST: "GET_PARTICIPANTS_USERS_REQUEST",
    GET_PARTICIPANTS_USERS_SUCCESS: "GET_PARTICIPANTS_USERS_SUCCESS",
    GET_PARTICIPANTS_USERS_FAILURE: "GET_PARTICIPANTS_USERS_FAILURE",

    GET_F2_WITHOUT_PARTICIPANTS_REQUEST: "GET_F2_WITHOUT_PARTICIPANTS_REQUEST",
    GET_F2_WITHOUT_PARTICIPANTS_SUCCESS: "GET_F2_WITHOUT_PARTICIPANTS_SUCCESS",
    GET_F2_WITHOUT_PARTICIPANTS_FAILURE: "GET_F2_WITHOUT_PARTICIPANTS_FAILURE",

    GET_TESTER_LIST_REQUEST: "GET_TESTER_LIST_REQUEST",
    GET_TESTER_LIST_SUCCESS: "GET_TESTER_LIST_SUCCESS",
    GET_TESTER_LIST_FAILURE: "GET_TESTER_LIST_FAILURE",

    DOWNLOAD_BACKUP_REQUEST: 'DOWNLOAD_BACKUP_REQUEST',
    DOWNLOAD_BACKUP_SUCCESS: 'DOWNLOAD_BACKUP_SUCCESS',
    DOWNLOAD_BACKUP_FAILURE: 'DOWNLOAD_BACKUP_FAILURE',

    DELETE_ORG_USER_REQUEST: "DELETE_ORG_USER_REQUEST",
    DELETE_ORG_USER_SUCCESS: "DELETE_ORG_USER_SUCCESS",
    DELETE_ORG_USER_FAILURE: "DELETE_ORG_USER_FAILURE",

    GET_EMPTY_USER_REQUEST: 'GET_EMPTY_USER_REQUEST',
    GET_EMPTY_USER_SUCCESS: 'GET_EMPTY_USER_SUCCESS',
    GET_EMPTY_USER_FAILURE: 'GET_EMPTY_USER_FAILURE',
};