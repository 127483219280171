import { userConstants, statusResponse } from '../_constants';
import { localStorageEnc } from '../_utilities';

let userData = localStorageEnc.getItem('tryio-admin-user');
let initialState = {};
if (userData) {
    userData = JSON.parse(userData);
    initialState = {
        login_status: statusResponse.SUCCESS,
        userData: userData,
        logout_status: statusResponse.FAILURE
    };
}

export function user(state = initialState, action) {
    switch (action.type) {
        case userConstants.USER_LOGIN_REQUEST:
            return {
                ...state,
                login_status: statusResponse.IN_PROGRESS,
                userData: action.data,
            };
        case userConstants.USER_LOGIN_SUCCESS:
            return {
                ...state,
                login_status: statusResponse.SUCCESS,
                userData: action.data
            };
        case userConstants.USER_LOGIN_FAILURE:
            return {
                ...state,
                login_status: statusResponse.FAILURE,
                userData: null
            };
        case userConstants.REGISTER_USER_REQUEST:
            return {
                ...state,
                register_status: statusResponse.IN_PROGRESS,
                registrationData: action.data
            };
        case userConstants.REGISTER_USER_SUCCESS:
            return {
                ...state,
                register_status: statusResponse.SUCCESS,
                registrationData: action.data
            };
        case userConstants.REGISTER_USER_FAILURE:
            return {
                ...state,
                register_status: statusResponse.FAILURE,
                registrationData: null
            };
        case userConstants.FORGOT_PASSWORD_REQUEST:
            return {
                ...state,
                forgot_password_status: statusResponse.IN_PROGRESS,
                forgotPasswordData: action.data
            };
        case userConstants.FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                forgot_password_status: statusResponse.SUCCESS,
                forgotPasswordData: action.data
            };
        case userConstants.FORGOT_PASSWORD_FAILURE:
            return {
                ...state,
                forgot_password_status: statusResponse.FAILURE,
                forgotPasswordData: null
            };
        case userConstants.RESET_PASSWORD_REQUEST:
            return {
                ...state,
                reset_password_status: statusResponse.IN_PROGRESS,
                user: action.data
            };
        case userConstants.RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                reset_password_status: statusResponse.SUCCESS,
                user: action.data
            };
        case userConstants.RESET_PASSWORD_FAILURE:
            return {
                ...state,
                reset_password_status: statusResponse.FAILURE,
                user: null
            };
        case userConstants.INVITE_USER_REQUEST:
            return {
                ...state,
                invite_user_status: statusResponse.IN_PROGRESS,
                user: action.data
            };
        case userConstants.INVITE_USER_SUCCESS:
            return {
                ...state,
                invite_user_status: statusResponse.SUCCESS,
                user: action.data
            };
        case userConstants.INVITE_USER_FAILURE:
            return {
                ...state,
                invite_user_status: statusResponse.FAILURE,
                user: null
            };
        case userConstants.VERIFY_ORG_USER_REQUEST:
            return {
                ...state,
                verify_org_user_status: statusResponse.IN_PROGRESS,
            };
        case userConstants.VERIFY_ORG_USER_SUCCESS:
            return {
                ...state,
                verify_org_user_status: statusResponse.SUCCESS,
            };
        case userConstants.VERIFY_ORG_USER_FAILURE:
            return {
                ...state,
                verify_org_user_status: statusResponse.FAILURE,
            };
        case userConstants.GET_F1_USERS_REQUEST:
            return {
                ...state,
                get_f1_users_status: statusResponse.IN_PROGRESS,
                f1UsersList: []
            };
        case userConstants.GET_F1_USERS_SUCCESS:
            return {
                ...state,
                get_f1_users_status: statusResponse.SUCCESS,
                f1UsersList: action.data
            };
        case userConstants.GET_F1_USERS_FAILURE:
            return {
                ...state,
                get_f1_users_status: statusResponse.FAILURE,
                f1UsersList: []
            };
        case userConstants.GET_F2_USERS_REQUEST:
            return {
                ...state,
                get_f2_users_status: statusResponse.IN_PROGRESS,
                f2UsersList: []
            };
        case userConstants.GET_F2_USERS_SUCCESS:
            return {
                ...state,
                get_f2_users_status: statusResponse.SUCCESS,
                f2UsersList: action.data
            };
        case userConstants.GET_F2_USERS_FAILURE:
            return {
                ...state,
                get_f2_users_status: statusResponse.FAILURE,
                f2UsersList: []
            };
        case userConstants.GET_PARTICIPANTS_USERS_REQUEST:
            return {
                ...state,
                get_participants_users_status: statusResponse.IN_PROGRESS,
                participantsUsersList: []
            };
        case userConstants.GET_PARTICIPANTS_USERS_SUCCESS:
            return {
                ...state,
                get_participants_users_status: statusResponse.SUCCESS,
                participantsUsersList: action.data
            };
        case userConstants.GET_PARTICIPANTS_USERS_FAILURE:
            return {
                ...state,
                get_participants_users_status: statusResponse.FAILURE,
                participantsUsersList: []
            };
        case userConstants.GET_F2_WITHOUT_PARTICIPANTS_REQUEST:
            return {
                ...state,
                get_f2_without_participants_status: statusResponse.IN_PROGRESS,
                f2WithoutParticipants: []
            };
        case userConstants.GET_F2_WITHOUT_PARTICIPANTS_SUCCESS:
            return {
                ...state,
                get_f2_without_participants_status: statusResponse.SUCCESS,
                f2WithoutParticipants: action.data
            };
        case userConstants.GET_F2_WITHOUT_PARTICIPANTS_FAILURE:
            return {
                ...state,
                get_f2_without_participants_status: statusResponse.FAILURE,
                f2WithoutParticipants: []
            };
        case userConstants.GET_TESTER_LIST_REQUEST:
            return {
                ...state,
                get_tester_list_status: statusResponse.IN_PROGRESS,
                testerList: []
            };
        case userConstants.GET_TESTER_LIST_SUCCESS:
            return {
                ...state,
                get_tester_list_status: statusResponse.SUCCESS,
                testerList: action.data
            };
        case userConstants.GET_TESTER_LIST_FAILURE:
            return {
                ...state,
                get_tester_list_status: statusResponse.FAILURE,
                testerList: []
            };
        case userConstants.ACTIVATE_ORG_USER_REQUEST:
            return {
                ...state,
                activate_org_user_status: statusResponse.IN_PROGRESS,
                activationData: null
            };
        case userConstants.ACTIVATE_ORG_USER_SUCCESS:
            return {
                ...state,
                activate_org_user_status: statusResponse.SUCCESS,
                activationData: action.data
            };
        case userConstants.ACTIVATE_ORG_USER_FAILURE:
            return {
                ...state,
                activate_org_user_status: statusResponse.FAILURE,
                activationData: null
            };
        case userConstants.USER_LOGOUT:
            return {
                ...state,
                logout_status: statusResponse.SUCCESS
            };
        case userConstants.DOWNLOAD_BACKUP_REQUEST:
            return {
                ...state,
                download_backup_status: statusResponse.IN_PROGRESS,
                download_url: action.data
            };
        case userConstants.DOWNLOAD_BACKUP_SUCCESS:
            return {
                ...state,
                download_backup_status: statusResponse.SUCCESS,
                download_url: action.data
            };
        case userConstants.DOWNLOAD_BACKUP_FAILURE:
            return {
                ...state,
                download_backup_status: statusResponse.FAILURE,
                download_url: null
            };
        case userConstants.DELETE_ORG_USER_REQUEST:
            return {
                ...state,
                delete_org_user_status: statusResponse.IN_PROGRESS,
                deletedUserData: null
            };
        case userConstants.DELETE_ORG_USER_SUCCESS:
            return {
                ...state,
                delete_org_user_status: statusResponse.SUCCESS,
                deletedUserData: action.data
            };
        case userConstants.DELETE_ORG_USER_FAILURE:
            return {
                ...state,
                delete_org_user_status: statusResponse.FAILURE,
                deletedUserData: null
            };
        case userConstants.GET_EMPTY_USER_REQUEST:
            return {
                ...state,
                get_empty_status: statusResponse.IN_PROGRESS,
                emptyUserData: null
            };
        case userConstants.GET_EMPTY_USER_SUCCESS:
            return {
                ...state,
                get_empty_status: statusResponse.SUCCESS,
                emptyUserData: action.data
            };
        case userConstants.GET_EMPTY_USER_FAILURE:
            return {
                ...state,
                get_empty_status: statusResponse.FAILURE,
                emptyUserData: null
            };
        default:
            return state;
    }
}