export const notificationsConstants = {
    GET_NOTIFICATION_TEMPLATES_REQUEST: "GET_NOTIFICATION_TEMPLATES_REQUEST",
    GET_NOTIFICATION_TEMPLATES_SUCCESS: "GET_NOTIFICATION_TEMPLATES_SUCCESS",
    GET_NOTIFICATION_TEMPLATES_FAILURE: "GET_NOTIFICATION_TEMPLATES_FAILURE",

    ADD_NOTIFICATION_TEMPLATES_REQUEST: "ADD_NOTIFICATION_TEMPLATES_REQUEST",
    ADD_NOTIFICATION_TEMPLATES_SUCCESS: "ADD_NOTIFICATION_TEMPLATES_SUCCESS",
    ADD_NOTIFICATION_TEMPLATES_FAILURE: "ADD_NOTIFICATION_TEMPLATES_FAILURE",

    EDIT_NOTIFICATION_TEMPLATES_REQUEST: "EDIT_NOTIFICATION_TEMPLATES_REQUEST",
    EDIT_NOTIFICATION_TEMPLATES_SUCCESS: "EDIT_NOTIFICATION_TEMPLATES_SUCCESS",
    EDIT_NOTIFICATION_TEMPLATES_FAILURE: "EDIT_NOTIFICATION_TEMPLATES_FAILURE",

    GET_TEMPLATE_TYPE_LIST_REQUEST: "GET_TEMPLATE_TYPE_LIST_REQUEST",
    GET_TEMPLATE_TYPE_LIST_SUCCESS: "GET_TEMPLATE_TYPE_LIST_SUCCESS",
    GET_TEMPLATE_TYPE_LIST_FAILURE: "GET_TEMPLATE_TYPE_LIST_FAILURE",

    SUBMIT_PUSH_NOTIFICATIONS_TO_USERS_REQUEST: "SUBMIT_PUSH_NOTIFICATIONS_TO_USERS_REQUEST",
    SUBMIT_PUSH_NOTIFICATIONS_TO_USERS_SUCCESS: "SUBMIT_PUSH_NOTIFICATIONS_TO_USERS_SUCCESS",
    SUBMIT_PUSH_NOTIFICATIONS_TO_USERS_FAILURE: "SUBMIT_PUSH_NOTIFICATIONS_TO_USERS_FAILURE",

    SEND_EMAIL_REQUEST: "SEND_EMAIL_REQUEST",
    SEND_EMAIL_SUCCESS: "SEND_EMAIL_SUCCESS",
    SEND_EMAIL_FAILURE: "SEND_EMAIL_FAILURE",

    CUSTOM_NOTIFICATIONS_TO_USERS_REQUEST: "CUSTOM_NOTIFICATIONS_TO_USERS_REQUEST",
    CUSTOM_NOTIFICATIONS_TO_USERS_SUCCESS: "CUSTOM_NOTIFICATIONS_TO_USERS_SUCCESS",
    CUSTOM_NOTIFICATIONS_TO_USERS_FAILURE: "CUSTOM_NOTIFICATIONS_TO_USERS_FAILURE",

    DELETE_NOTOFICATION_REQUEST: 'DELETE_NOTOFICATION_REQUEST',
    DELETE_NOTOFICATION_SUCCESS: 'DELETE_NOTOFICATION_SUCCESS',
    DELETE_NOTOFICATION_FAILURE: 'DELETE_NOTOFICATION_FAILURE',
};