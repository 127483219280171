import { testersConstant, statusResponse } from '../_constants';

export function testers(state = {}, action) {
    console.log(action)
    switch (action.type) {
        case testersConstant.FILTER_TESTERS_REQUEST:
            return {
                ...state,
                filter_testers_status: statusResponse.IN_PROGRESS,
                filterTestersData: null
            };
        case testersConstant.FILTER_TESTERS_SUCCESS:
            return {
                ...state,
                filter_testers_status: statusResponse.SUCCESS,
                filterTestersData: action.data
            };
        case testersConstant.FILTER_TESTERS_FAILURE:
            return {
                ...state,
                filter_testers_status: statusResponse.FAILURE,
                filterTestersData: null
            };
        case testersConstant.GET_TESTERS_REQUEST:
            return {
                ...state,
                get_testers_list: statusResponse.IN_PROGRESS,
                testersList: null
            };
        case testersConstant.GET_TESTERS_SUCCESS:
            return {
                ...state,
                get_testers_list: statusResponse.SUCCESS,
                testersList: action.data
            };
        case testersConstant.GET_TESTERS_FAILURE:
            return {
                ...state,
                get_testers_list: statusResponse.FAILURE,
                testersList: null
            };
        case testersConstant.DELETE_TESTER_REQUEST:
            return {
                ...state,
                delete_tester_status: statusResponse.IN_PROGRESS,
                deletedTesterData: null
            };
        case testersConstant.DELETE_TESTER_SUCCESS:
            return {
                ...state,
                delete_tester_status: statusResponse.SUCCESS,
                deletedTesterData: action.data
            };
        case testersConstant.DELETE_TESTER_FAILURE:
            return {
                ...state,
                delete_tester_status: statusResponse.FAILURE,
                deletedTesterData: null
            };
        case testersConstant.CHANGE_TESTER_STATUS_REQUEST:
            return {
                ...state,
                change_tester_status_status: statusResponse.IN_PROGRESS,
                changeTesterStatusData: null
            };
        case testersConstant.CHANGE_TESTER_STATUS_SUCCESS:
            return {
                ...state,
                change_tester_status_status: statusResponse.SUCCESS,
                changeTesterStatusData: action.data
            };
        case testersConstant.CHANGE_TESTER_STATUS_FAILURE:
            return {
                ...state,
                change_tester_status_status: statusResponse.FAILURE,
                changeTesterStatusData: null
            };
        default:
            return state;
    }
}