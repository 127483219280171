export const componentType = {
    DESCRIPTION: "desc",
    MULTIPLE_CHOICE: "multiChoice",
    FREE_TEXT: "freeTxt",
    SINGLE_CHOICE: "singleChoice",
    OPINION: "opinion",
    RATE: "rating",
    GRID: "grid",
    LINESCALE: "lineScale",
    HEDONICSCALE: "hedonicScale",
    JAR: "JAR"
};