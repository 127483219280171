import { f2UserConstant, userConstants } from '../_constants';

let initialState = {
    testers: [],
    totalDocs: 0,
    totalPages: 0,
    currentPage: 1,
    limit: 10,
    paginationCounter: [1],
    searchText: ""
};

export function f2Users(state = initialState, action){
    switch(action.type){
        case f2UserConstant.HANDLE_TESTERS:
            return {
                ...state,
                ...action.data
            };
        case f2UserConstant.SET_TESTERS:
            return {
                ...state,
                organization: action.data
            };
        case f2UserConstant.SET_TESTERS_TOTAL_PAGE:
            return {
                ...state,
                totalPages: action.data
            };
        case f2UserConstant.SET_TESTERS_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.data
            };
        case f2UserConstant.SET_TESTERS_SEARCH_TEXT:
            return {
                ...state,
                searchText: action.data
            }
        case userConstants.USER_LOGOUT:
            return initialState;
        default:
            return state;
    }
}