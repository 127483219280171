import { organizationConstants, userConstants } from '../_constants';

let initialState = {
    organization: [],
    totalDocs: 0,
    totalPages: 0,
    currentPage: 1,
    limit: 10,
    paginationCounter: [1],
    searchText: ""
};

export function organization(state = initialState, action){
    console.log(action)
    switch(action.type){
        case organizationConstants.HANDLE_ORGANIZATION:
            return {
                ...state,
                ...action.data
            };
        case organizationConstants.SET_ORGANIZATION:
            return {
                ...state,
                organization: action.data
            };
        case organizationConstants.SET_TOTAL_PAGE:
            return {
                ...state,
                totalPages: action.data
            };
        case organizationConstants.SET_CURRENT_PAGE:
            return {
                ...state,
                currentPage: action.data
            };
        case organizationConstants.SET_SEARCH_TEXT:
            return {
                ...state,
                searchText: action.data
            }
        case userConstants.USER_LOGOUT:
            return initialState;
        default:
            return state;
    }
}