import { testConstants, statusResponse } from '../_constants';

export function test(state = {}, action) {
    switch (action.type) {
        case testConstants.GET_TEST_LIST_REQUEST:
            return {
                ...state,
                get_test_list_status: statusResponse.IN_PROGRESS,
                testList: action.data
            };
        case testConstants.GET_TEST_LIST_SUCCESS:
            return {
                ...state,
                get_test_list_status: statusResponse.SUCCESS,
                testList: action.data
            };
        case testConstants.GET_TEST_LIST_FAILURE:
            return {
                ...state,
                get_test_list_status: statusResponse.FAILURE,
                testList: null
            };
        case testConstants.GET_TEST_REQUEST:
            return {
                ...state,
                get_test_status: statusResponse.IN_PROGRESS,
                testData: action.data
            };
        case testConstants.GET_TEST_SUCCESS:
            return {
                ...state,
                get_test_status: statusResponse.SUCCESS,
                testData: action.data
            };
        case testConstants.GET_TEST_FAILURE:
            return {
                ...state,
                get_test_status: statusResponse.FAILURE,
                testData: null
            };
        case testConstants.DELETE_TEST_REQUEST:
            return {
                ...state,
                delete_test_status: statusResponse.IN_PROGRESS,
                deletedTest: action.data
            };
        case testConstants.DELETE_TEST_SUCCESS:
            return {
                ...state,
                delete_test_status: statusResponse.SUCCESS,
                deletedTest: action.data
            };
        case testConstants.DELETE_TEST_FAILURE:
            return {
                ...state,
                delete_test_status: statusResponse.FAILURE,
                deletedTest: null
            };
        case testConstants.UPDATE_TEST_REQUEST:
            return {
                ...state,
                update_test_status: statusResponse.IN_PROGRESS,
                updatedTest: action.data
            };
        case testConstants.UPDATE_TEST_SUCCESS:
            return {
                ...state,
                update_test_status: statusResponse.SUCCESS,
                updatedTest: action.data
            };
        case testConstants.UPDATE_TEST_FAILURE:
            return {
                ...state,
                update_test_status: statusResponse.FAILURE,
                updatedTest: null
            };
        case testConstants.GET_FAMILY_SITUATION_REQUEST:
            return {
                ...state,
                get_family_situation_status: statusResponse.IN_PROGRESS,
                familySituationData: null
            };
        case testConstants.GET_FAMILY_SITUATION_SUCCESS:
            return {
                ...state,
                get_family_situation_status: statusResponse.SUCCESS,
                familySituationData: action.data
            };
        case testConstants.GET_FAMILY_SITUATION_FAILURE:
            return {
                ...state,
                get_family_situation_status: statusResponse.FAILURE,
                familySituationData: null
            };
        case testConstants.GET_EDUCATION_LEVEL_REQUEST:
            return {
                ...state,
                get_education_level_status: statusResponse.IN_PROGRESS,
                educationLevelData: null
            };
        case testConstants.GET_EDUCATION_LEVEL_SUCCESS:
            return {
                ...state,
                get_education_level_status: statusResponse.SUCCESS,
                educationLevelData: action.data
            };
        case testConstants.GET_EDUCATION_LEVEL_FAILURE:
            return {
                ...state,
                get_education_level_status: statusResponse.FAILURE,
                educationLevelData: null
            };
        case testConstants.GET_INCOME_LEVEL_REQUEST:
            return {
                ...state,
                get_income_level_status: statusResponse.IN_PROGRESS,
                incomeLevelData: null
            };
        case testConstants.GET_INCOME_LEVEL_SUCCESS:
            return {
                ...state,
                get_income_level_status: statusResponse.SUCCESS,
                incomeLevelData: action.data
            };
        case testConstants.GET_INCOME_LEVEL_FAILURE:
            return {
                ...state,
                get_income_level_status: statusResponse.FAILURE,
                incomeLevelData: null
            };
        case testConstants.GET_CRON_JOB_DATA_REQUEST:
            return {
                ...state,
                get_cron_job_data_status: statusResponse.IN_PROGRESS,
                cronJobData: null
            };
        case testConstants.GET_CRON_JOB_DATA_SUCCESS:
            return {
                ...state,
                get_cron_job_data_status: statusResponse.SUCCESS,
                cronJobData: action.data
            };
        case testConstants.GET_CRON_JOB_DATA_FAILURE:
            return {
                ...state,
                get_cron_job_data_status: statusResponse.FAILURE,
                cronJobData: null
            };
        case testConstants.SET_CRON_JOB_DATA_REQUEST:
            return {
                ...state,
                set_cron_job_data_status: statusResponse.IN_PROGRESS,
            };
        case testConstants.SET_CRON_JOB_DATA_SUCCESS:
            return {
                ...state,
                set_cron_job_data_status: statusResponse.SUCCESS,
            };
        case testConstants.SET_CRON_JOB_DATA_FAILURE:
            return {
                ...state,
                set_cron_job_data_status: statusResponse.FAILURE,
            };
        case testConstants.GET_TEST_GALLERY_REQUEST:
            return {
                ...state,
                get_test_gallary_status: statusResponse.IN_PROGRESS,
                testGallary: null
            };
        case testConstants.GET_TEST_GALLERY_SUCCESS:
            return {
                ...state,
                get_test_gallary_status: statusResponse.SUCCESS,
                testGallary: action.data
            };
        case testConstants.GET_TEST_GALLERY_FAILURE:
            return {
                ...state,
                get_test_gallary_status: statusResponse.FAILURE,
                testGallary: null
            };
        case testConstants.UPLOAD_GALLERY_IMAGE_REQUEST:
            return {
                ...state,
                upload_gallery_image_status: statusResponse.IN_PROGRESS,
                uploadImageData: null
            };
        case testConstants.UPLOAD_GALLERY_IMAGE_SUCCESS:
            return {
                ...state,
                upload_gallery_image_status: statusResponse.SUCCESS,
                uploadImageData: action.data
            };
        case testConstants.UPLOAD_GALLERY_IMAGE_FAILURE:
            return {
                ...state,
                upload_gallery_image_status: statusResponse.FAILURE,
                uploadImageData: null
            };
        case testConstants.GET_TEMPLATE_REQUEST:
            return {
                ...state,
                get_template_status: statusResponse.IN_PROGRESS,
                templateList: null
            };
        case testConstants.GET_TEMPLATE_SUCCESS:
            return {
                ...state,
                get_template_status: statusResponse.SUCCESS,
                templateList: action.data
            };
        case testConstants.GET_TEMPLATE_FAILURE:
            return {
                ...state,
                get_template_status: statusResponse.FAILURE,
                templateList: null
            };
        case testConstants.SET_TEMPLATE_REQUEST:
            return {
                ...state,
                set_template_status: statusResponse.IN_PROGRESS,
            };
        case testConstants.SET_TEMPLATE_SUCCESS:
            return {
                ...state,
                set_template_status: statusResponse.SUCCESS,
            };
        case testConstants.SET_TEMPLATE_FAILURE:
            return {
                ...state,
                set_template_status: statusResponse.FAILURE,
            };
        case testConstants.GET_ALL_QUESTIONNAIRE_REQUEST:
            return {
                ...state,
                get_all_questionnaire_status: statusResponse.IN_PROGRESS,
                allQuestionnaireList: null
            };
        case testConstants.GET_ALL_QUESTIONNAIRE_SUCCESS:
            return {
                ...state,
                get_all_questionnaire_status: statusResponse.SUCCESS,
                allQuestionnaireList: action.data
            };
        case testConstants.GET_ALL_QUESTIONNAIRE_FAILURE:
            return {
                ...state,
                get_all_questionnaire_status: statusResponse.FAILURE,
                allQuestionnaireList: null
            };
        case testConstants.GET_LOCATION_REQUEST:
            return {
                ...state,
                location_list_status: statusResponse.IN_PROGRESS,
                locationList: null
            };
        case testConstants.GET_LOCATION_SUCCESS:
            return {
                ...state,
                location_list_status: statusResponse.SUCCESS,
                locationList: action.data
            };
        case testConstants.GET_LOCATION_FAILURE:
            return {
                ...state,
                location_list_status: statusResponse.FAILURE,
                locationList: null
            };
        case testConstants.UPDATE_VIEW_TEST_REQUEST:
            return {
                ...state,
                update_View_test_status: statusResponse.IN_PROGRESS,
                updateViewTest: null
            };
        case testConstants.UPDATE_VIEW_TEST_SUCCESS:
            return {
                ...state,
                update_View_test_status: statusResponse.SUCCESS,
                updateViewTest: action.data
            };
        case testConstants.UPDATE_VIEW_TEST_FAILURE:
            return {
                ...state,
                update_View_test_status: statusResponse.FAILURE,
                updateViewTest: null
            };
        case testConstants.RUN_ALGORITHM_REQUEST:
            return {
                ...state,
                run_algorithm_status: statusResponse.IN_PROGRESS,
                algorithemData: null
            };
        case testConstants.RUN_ALGORITHM_SUCCESS:
            return {
                ...state,
                run_algorithm_status: statusResponse.SUCCESS,
                algorithemData: action.data
            };
        case testConstants.RUN_ALGORITHM_FAILURE:
            return {
                ...state,
                run_algorithm_status: statusResponse.FAILURE,
                algorithemData: null
            };
        case testConstants.GET_TESTS_LIST_REQUEST:
            return {
                ...state,
                get_tests_list_status: statusResponse.IN_PROGRESS,
                testsList: null
            };
        case testConstants.GET_TESTS_LIST_SUCCESS:
            return {
                ...state,
                get_tests_list_status: statusResponse.SUCCESS,
                testsList: action.data
            };
        case testConstants.GET_TESTS_LIST_FAILURE:
            return {
                ...state,
                get_tests_list_status: statusResponse.FAILURE,
                testsList: null
            };
        case testConstants.DELETE_TESTS_LIST_REQUEST:
            return {
                ...state,
                delete_tests_list_status: statusResponse.IN_PROGRESS,
            };
        case testConstants.DELETE_TESTS_LIST_SUCCESS:
            return {
                ...state,
                delete_tests_list_status: statusResponse.SUCCESS,
            };
        case testConstants.DELETE_TESTS_LIST_FAILURE:
            return {
                ...state,
                delete_tests_list_status: statusResponse.FAILURE,
            };
        case testConstants.GET_CURRENCY_REQUEST:
            return {
                ...state,
                currency_list_status: statusResponse.IN_PROGRESS,
            };
        case testConstants.GET_CURRENCY_SUCCESS:
            return {
                ...state,
                currency_list_status: statusResponse.SUCCESS,
                currencyData: action.data
            };
        case testConstants.GET_CURRENCY_FAILURE:
            return {
                ...state,
                currency_list_status: statusResponse.FAILURE,
            };
        case testConstants.FILTER_TESTS_LIST_REQUEST:
            return {
                ...state,
                filter_tests_list_status: statusResponse.IN_PROGRESS,
                filteredTests: null
            };
        case testConstants.FILTER_TESTS_LIST_SUCCESS:
            return {
                ...state,
                filter_tests_list_status: statusResponse.SUCCESS,
                filteredTests: action.data
            };
        case testConstants.FILTER_TESTS_LIST_FAILURE:
            return {
                ...state,
                filter_tests_list_status: statusResponse.FAILURE,
                filteredTests: null
            };
        default:
            return state;
    }
}