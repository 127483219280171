export const algorithemConstants = {
    GET_ALGO_DETAIL_REQUEST: "GET_ALGO_DETAIL_REQUEST",
    GET_ALGO_DETAIL_SUCCESS: "GET_ALGO_DETAIL_SUCCESS",
    GET_ALGO_DETAIL_FAILURE: "GET_ALGO_DETAIL_FAILURE",

    GET_F1_USER_REQUEST: 'GET_F1_USER_REQUEST',
    GET_F1_USER_SUCCESS: 'GET_F1_USER_SUCCESS',
    GET_F1_USER_FAILURE: 'GET_F1_USER_FAILURE',

    GET_F2_USER_REQUEST: 'GET_F2_USER_REQUEST',
    GET_F2_USER_SUCCESS: 'GET_F2_USER_SUCCESS',
    GET_F2_USER_FAILURE: 'GET_F2_USER_FAILURE',

    GET_PARTICIPATE_USER_REQUEST: 'GET_PARTICIPATE_USER_REQUEST',
    GET_PARTICIPATE_USER_SUCCESS: 'GET_PARTICIPATE_USER_SUCCESS',
    GET_PARTICIPATE_USER_FAILURE: 'GET_PARTICIPATE_USER_FAILURE',

    DELETE_PARTICIPATE_USER_REQUEST: 'DELETE_PARTICIPATE_USER_REQUEST',
    DELETE_PARTICIPATE_USER_SUCCESS: 'DELETE_PARTICIPATE_USER_SUCCESS',
    DELETE_PARTICIPATE_USER_FAILURE: 'DELETE_PARTICIPATE_USER_FAILURE',
};