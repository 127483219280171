import { blockedlistDomain } from '../_constants';

let initialState = {
    blockedDomain: []
};

export function blockedDomain(state = initialState, action){
    switch(action.type){
        case blockedlistDomain.BLOCKED_LIST_DOMAIN:
            return {
                ...state,
                blockedDomain: action.data
            };
        default:
            return state;
    }
}