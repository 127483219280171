import { questionnaireConstants, statusResponse } from '../_constants';

export function questionnaire(state = {}, action) {
    switch (action.type) {
        case questionnaireConstants.ADD_QUESTIONNAIRE_REQUEST:
            return {
                ...state,
                add_questionnaire_status: statusResponse.IN_PROGRESS,
                addedQuestionnaire: action.data
            };
        case questionnaireConstants.ADD_QUESTIONNAIRE_SUCCESS:
            return {
                ...state,
                add_questionnaire_status: statusResponse.SUCCESS,
                addedQuestionnaire: action.data
            };
        case questionnaireConstants.ADD_QUESTIONNAIRE_FAILURE:
            return {
                ...state,
                add_questionnaire_status: statusResponse.FAILURE,
                addedQuestionnaire: null
            };
        case questionnaireConstants.GET_INDIVIDUAL_QUESTIONNAIRE_REQUEST:
            return {
                ...state,
                get_individual_questionnaire_status: statusResponse.IN_PROGRESS,
                individualQuestionnaire: action.data
            };
        case questionnaireConstants.GET_INDIVIDUAL_QUESTIONNAIRE_SUCCESS:
            return {
                ...state,
                get_individual_questionnaire_status: statusResponse.SUCCESS,
                individualQuestionnaire: action.data
            };
        case questionnaireConstants.GET_INDIVIDUAL_QUESTIONNAIRE_FAILURE:
            return {
                ...state,
                get_individual_questionnaire_status: statusResponse.FAILURE,
                individualQuestionnaire: null
            };
        case questionnaireConstants.GET_RESPONSES_QUESTIONNAIRE_REQUEST:
            return {
                ...state,
                get_responses_questionnaire_status: statusResponse.IN_PROGRESS,
                responsesQuestionnaireData: action.data
            };
        case questionnaireConstants.GET_RESPONSES_QUESTIONNAIRE_SUCCESS:
            return {
                ...state,
                get_responses_questionnaire_status: statusResponse.SUCCESS,
                responsesQuestionnaireData: action.data
            };
        case questionnaireConstants.GET_RESPONSES_QUESTIONNAIRE_FAILURE:
            return {
                ...state,
                get_responses_questionnaire_status: statusResponse.FAILURE,
                responsesQuestionnaireData: null
            };
        case questionnaireConstants.GET_TEST_SUMMARY_REQUEST:
            return {
                ...state,
                get_test_summary_status: statusResponse.IN_PROGRESS,
                testSummaryData: action.data
            };
        case questionnaireConstants.GET_TEST_SUMMARY_SUCCESS:
            return {
                ...state,
                get_test_summary_status: statusResponse.SUCCESS,
                testSummaryData: action.data
            };
        case questionnaireConstants.GET_TEST_SUMMARY_FAILURE:
            return {
                ...state,
                get_test_summary_status: statusResponse.FAILURE,
                testSummaryData: null
            };
        case questionnaireConstants.GET_TEST_SUMMARY_FOR_SESSION_REQUEST:
            return {
                ...state,
                get_test_summary_session_status: statusResponse.IN_PROGRESS,
                testSummaryDataForSession: action.data
            };
        case questionnaireConstants.GET_TEST_SUMMARY_FOR_SESSION_SUCCESS:
            return {
                ...state,
                get_test_summary_session_status: statusResponse.SUCCESS,
                testSummaryDataForSession: action.data
            };
        case questionnaireConstants.GET_TEST_SUMMARY_FOR_SESSION_FAILURE:
            return {
                ...state,
                get_test_summary_session_status: statusResponse.FAILURE,
                testSummaryDataForSession: null
            };
        case questionnaireConstants.GET_DOWNLOAD_REQUEST:
            return {
                ...state,
                get_dowload_file_status: statusResponse.IN_PROGRESS,
                downloadFileUrl: action.data
            };
        case questionnaireConstants.GET_DOWNLOAD_SUCCESS:
            return {
                ...state,
                get_dowload_file_status: statusResponse.SUCCESS,
                downloadFileUrl: action.data
            };
        case questionnaireConstants.GET_DOWNLOAD_FAILURE:
            return {
                ...state,
                get_dowload_file_status: statusResponse.FAILURE,
                downloadFileUrl: null
            };
        case questionnaireConstants.PRINT_SUMMARY_REQUEST:
            return {
                ...state,
                print_summary_status: statusResponse.IN_PROGRESS,
                printedSummaryFile: action.data
            };
        case questionnaireConstants.PRINT_SUMMARY_SUCCESS:
            return {
                ...state,
                print_summary_status: statusResponse.SUCCESS,
                printedSummaryFile: action.data
            };
        case questionnaireConstants.PRINT_SUMMARY_FAILURE:
            return {
                ...state,
                print_summary_status: statusResponse.FAILURE,
                printedSummaryFile: null
            };
        default:
            return state;
    }
}