export const locationConstants = {
    GET_LOCATION_REQUEST: 'GET_LOCATION_REQUEST',
    GET_LOCATION_SUCCESS: 'GET_LOCATION_SUCCESS',
    GET_LOCATION_FAILURE: 'GET_LOCATION_FAILURE',

    GET_LANGUAGE_REQUEST: 'GET_LANGUAGE_REQUEST',
    GET_LANGUAGE_SUCCESS: 'GET_LANGUAGE_SUCCESS',
    GET_LANGUAGE_FAILURE: 'GET_LANGUAGE_FAILURE',

    DELETE_LOCATION_REQUEST: 'DELETE_LOCATION_REQUEST',
    DELETE_LOCATION_SUCCESS: 'DELETE_LOCATION_SUCCESS',
    DELETE_LOCATION_FAILURE: 'DELETE_LOCATION_FAILURE',

    ADD_LOCATION_REQUEST: 'ADD_LOCATION_REQUEST',
    ADD_LOCATION_SUCCESS: 'ADD_LOCATION_SUCCESS',
    ADD_LOCATION_FAILURE: 'ADD_LOCATION_FAILURE',

    GET_EDIT_LOCATION_REQUEST: 'GET_EDIT_LOCATION_REQUEST',
    GET_EDIT_LOCATION_SUCCESS: 'GET_EDIT_LOCATION_SUCCESS',
    GET_EDIT_LOCATION_FAILURE: 'GET_EDIT_LOCATION_FAILURE',

    EDIT_LOCATION_REQUEST: 'EDIT_LOCATION_REQUEST',
    EDIT_LOCATION_SUCCESS: 'EDIT_LOCATION_SUCCESS',
    EDIT_LOCATION_FAILURE: 'EDIT_LOCATION_FAILURE',

    GET_COUNTRY_LIST_REQUEST: 'GET_COUNTRY_LIST_REQUEST',
    GET_COUNTRY_LIST_SUCCESS: 'GET_COUNTRY_LIST_SUCCESS',
    GET_COUNTRY_LIST_FAILURE: 'GET_COUNTRY_LIST_FAILURE',

    UPLOAD_COUNTRY_LOCATION_REQUEST: 'UPLOAD_COUNTRY_LOCATION_REQUEST',
    UPLOAD_COUNTRY_LOCATION_SUCCESS: 'UPLOAD_COUNTRY_LOCATION_SUCCESS',
    UPLOAD_COUNTRY_LOCATION_FAILURE: 'UPLOAD_COUNTRY_LOCATION_FAILURE',

    UPLOAD_CITY_LOCATION_REQUEST: 'UPLOAD_CITY_LOCATION_REQUEST',
    UPLOAD_CITY_LOCATION_SUCCESS: 'UPLOAD_CITY_LOCATION_SUCCESS',
    UPLOAD_CITY_LOCATION_FAILURE: 'UPLOAD_CITY_LOCATION_FAILURE',
};